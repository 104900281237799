/* eslint-disable camelcase */
import React, { Component } from "react"
import { connect } from "react-redux"
import cx from "classnames"
import PropTypes from "prop-types"
import { GoogleLogin } from "react-google-login"
import FacebookLogin from "react-facebook-login"
import { getAuthToken } from "../../common/tool/auth"
import HeadSEOTags from "../../common/components/HeadSEOTags"
import {
    reInitSession,
    socialUserLoginCheck,
    socialUserLogin,
    socialUserSignup,
    forgotPassword,
    localUserLogin,
    authenticate,
    getProfile,
    updateRequestedPath,
    clearUserDetails,
} from "../../redux/actions/LoginActions"
import { InitWishlist } from "../../redux/actions/WishlistActions"
import { getMeta } from "../../redux/actions/MetaAction"
import { _isEmpty, getBackTo, pad } from "../../common/tool/util"
import Style from "./login.less"
import styles from "./index.less"
import config, { t } from "../../../config"
import stylesPopup from "../product/components/pop-up.less"
import StylesSprite from "../../assets/less/sprite.less"

let popUpDiv
let becomeMemberDiv
class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showPassword: false,
            email: "",
            emailError: null,
            password: "",
            passwordError: null,
            fbid: "",
            fbaccessToken: "",
            fbfirst_name: "",
            fblast_name: "",
            lockSignInButton: false,
        }
        this.props.reInitSession()
    }

    UNSAFE_componentWillMount() {
        // console.log("will mount at login page: ", shouldUpdate)
        if (this.props.requestedPath === "/") {
            this.props.authenticate()
        } else {
            this.props.authenticate(this.props.requestedPath)
        }
    }

    componentDidMount() {
        this.props.getMeta("login")
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.authenticated || this.props.authenticated) {
            if (nextProps.requestedPath === "/") {
                if (window.lastLocation) {
                    // console.log("User should previous page")
                    this.props.history.goBack()
                } else {
                    this.props.history.replace("/account.html")
                }
            } else if (nextProps.requestedPath === "reservation-search") {
                document.location.replace(`${config.url.t4fUrl}/mobileRedirect/redirectUrl?token=${getAuthToken()}&page=my-bookings`)
            } else if (/#tr_photos-upload$/.test(nextProps.requestedPath)) {
                this.props.history.replace(nextProps.requestedPath)
            } else if (nextProps.requestedPath === "page=product-review") {
                document.location.replace(`${config.url.t4fUrl}/mobileRedirect/redirectUrl?token=${getAuthToken()}&page=product-review`)
            } else {
                // this.props.history.push(nextProps.requestedPath)
                this.props.history.goBack()
            }
        } else if (!_isEmpty(nextProps.registeredData)) {
            // console.log("nextProps.socialResponce", nextProps.socialResponce)
            if (!nextProps.registeredData.registered
                && nextProps.socialResponce.email === "undefined") {
                this.setState({
                    fbid: nextProps.socialResponce.userId,
                    fbaccessToken: nextProps.socialResponce.accessToken,
                    fbfirst_name: nextProps.socialResponce.firstName,
                    fblast_name: nextProps.socialResponce.lastName,
                })
                this.becomeMemberToggle()
            }
        }
        if (nextProps.userDetails.ERROR) {
            this.setState({
                lockSignInButton: false,
            })
        }
    }

    getFacebookRedirectURI = () => config.host + "/login"

    handleGoBack= () => {
        const backTo = getBackTo(this.props.location.search)
        if (backTo) {
            this.props.history.replace(decodeURIComponent(backTo))
            return
        }
        if (window.lastLocation) {
            if (this.props.requestedPath === "/signup") {
                this.props.history.replace("/")
                return
            }
            if (this.props.requestedPath === `${config.url.t4fUrl}/account.html#reservation-search`) {
                this.props.updateRequestedPath("/")
                this.props.history.replace("/")
                return
            }
            this.props.history.goBack()
            return
        }
        this.props.history.replace("/")
    }

    popupToggle= () => {
        popUpDiv.classList.toggle(stylesPopup.disNone)
    }

    becomeMemberToggle= () => {
        becomeMemberDiv.classList.toggle(stylesPopup.disNone)
    }

    responseFacebook = response => {
        let gender = ""
        let birthday = ""
        if (response.gender === "male") {
            gender = "m"
        } else if (response.gender === "female") {
            gender = "f"
        }
        if (typeof response.birthday !== "undefined") {
            birthday = response.birthday
            if (birthday) {
                birthday = birthday.split("/")
                birthday = birthday[2] + "-" + birthday[0] + "-" + birthday[1]
            }
        }
        if (typeof response.status !== "undefined") {
            if (response.status === "unknown") {
                alert("Login fail")
                console.error("Facebook Login fail")
            }
        } else if (typeof response.email === "undefined") {
            // console.log("Facebook response", response)
            this.props.socialUserLoginCheck(
                "undefined",
                response.id,
                response.expiresIn,
                response.accessToken,
                "facebook",
                response.first_name,
                response.last_name,
                gender,
                birthday,
            )
        } else {
            this.props.socialUserLoginCheck(
                response.email,
                response.id,
                response.expiresIn,
                response.accessToken,
                "facebook",
                response.first_name,
                response.last_name,
                gender,
                birthday,
            )
        }
    }

    responseGoogleSuccess = response => {
        window.gapi.client
            .load("https://content.googleapis.com/discovery/v1/apis/people/v1/rest")
            .then(() => {
                window.gapi.client.people.people.get({
                    resourceName: "people/me",
                    personFields: "names,emailAddresses,birthdays,genders",
                }).then(rdata => {
                    let gender = ""
                    let birthday = ""
                    if (
                        rdata && rdata.result && rdata.result.genders
                        && rdata.result.genders.length > 0
                    ) {
                        if (rdata.result.genders[0].value === "male") {
                            gender = "m"
                        } else if (rdata.result.genders[0].value === "female") {
                            gender = "f"
                        } else if (rdata.result.genders[0].value === "other") {
                            gender = "o"
                        }
                    }
                    if (
                        rdata && rdata.result && rdata.result.birthdays
                        && rdata.result.birthdays.length > 0
                    ) {
                        if (typeof rdata.result.birthdays[0].date !== "undefined") {
                            birthday += pad(rdata.result.birthdays[0].date.year, 4)
                            birthday += "-" + pad(rdata.result.birthdays[0].date.month, 2)
                            birthday += "-" + pad(rdata.result.birthdays[0].date.day, 2)
                        }
                    }
                    this.props.socialUserLoginCheck(
                        response.profileObj.email,
                        response.profileObj.googleId,
                        response.tokenObj.expires_at,
                        response.accessToken,
                        "google",
                        response.profileObj.givenName,
                        response.profileObj.familyName,
                        gender,
                        birthday,
                    )
                })
            })
    }

    validateForm = (email, password) => {
        const emailError = (email === "") ? t("Please enter your Email") : null
        this.setState({ emailError })
        const passwordError = (password === "") ? t("Please enter a Password") : null
        this.setState({ passwordError })
        if (emailError === null && passwordError === null) {
            return true
        }
        return false
    }

    responseGoogleError = e => {
        const condition1 = typeof e.details !== "undefined" && e.details.includes("Not a valid origin for the client")
        const condition2 = e.error === "popup_closed_by_user"
        if (!condition1 && !condition2) {
            alert(t("login fail"))
        }
    }

    responseFacebookError = () => {
        console.error("Facebook login fail")
        alert(t("login fail"))
    }

    handleSubmit = e => {
        e.preventDefault()
        // stop duplicate processing
        if (this.state.lockSignInButton) {
            return
        }
        this.props.clearUserDetails()
        this.setState({
            lockSignInButton: true,
        })
        if (this.validateForm(e.target.email.value, e.target.password.value)) {
            this.props.localUserLogin(
                e.target.email.value,
                e.target.password.value,
            )
        } else {
            // release SignIn button
            this.setState({
                lockSignInButton: false,
            })
        }
    }

    goForSignup = () => {
        this.props.clearUserDetails()
        let signUpPath = "/signup"
        const backTo = getBackTo(this.props.location.search)
        if (backTo) {
            signUpPath += `?backTo=${backTo}`
        }
        this.props.history.replace(signUpPath)
    }

    forgotSubmitHandler= e => {
        e.preventDefault()
        this.props.forgotPassword(e.target.email.value, () => {
            this.popupToggle()
        })
    }

    becomeMemberSubmitHandler= e => {
        e.preventDefault()
        if (e.target.email.value !== ""
            && e.target.first_name.value !== ""
            && e.target.last_name.value !== "") {
            this.props.socialUserSignup(
                e.target.email.value,
                this.state.fbid,
                this.state.fbaccessToken,
                "facebook",
                e.target.first_name.value,
                e.target.last_name.value,
            )
        }
    }

    passwordToogle= () => {
        this.setState(state => ({ showPassword: !state.showPassword }))
    }

    render() {
        return (
            <div>
                <HeadSEOTags {...this.props.MetaData} />
                <div className={styles.login_bg} />
                <div className={styles.container}>
                    <div
                        onClick={this.handleGoBack}
                        className={Style.close_login}
                    >
                        <em className={`${StylesSprite.sprite_base} ${styles.close_sign_popup}`} />
                    </div>
                    <div className={Style.loginlogo}>
                        <img src="../../public/img/login-logo.png" alt="" title="" />
                    </div>
                    <div className={Style.login_form_div}>
                        <form onSubmit={this.handleSubmit}>
                            <div className={cx(`${styles.group} ${styles.group_margin}`, { [styles.red_error]: this.state.emailError !== null })}>
                                <input
                                    type="email"
                                    name="email"
                                    value={this.state.email}
                                    onChange={e => this.setState({ email: e.target.value })}
                                    required
                                />
                                <span className={styles.highlight} />
                                <span className={styles.bar} />
                                <span
                                    className={cx(styles.placeholderlabel, { [styles.labeltop]: this.state.email !== "" })}
                                    htmlFor={t("email")}
                                >
                                    {t("email")}
                                </span>
                                {this.state.emailError !== null
                                    && (
                                        <span className={styles.error_span}>
                                            {t(this.state.emailError)}
                                        </span>
                                    )}
                            </div>
                            <div className={cx(`${styles.group} ${styles.group_margin}`, { [styles.red_error]: this.state.passwordError !== null })}>
                                {this.state.showPassword
                                    ? (
                                        <input
                                            type="text"
                                            name="password"
                                            value={this.state.password}
                                            onChange={
                                                e => this.setState({ password: e.target.value })
                                            }
                                            required
                                        />
                                    )
                                    : (
                                        <input
                                            type="password"
                                            name="password"
                                            value={this.state.password}
                                            onChange={
                                                e => this.setState({ password: e.target.value })
                                            }
                                            required
                                        />
                                    )}
                                {this.state.showPassword
                                    ? (
                                        <span
                                            onClick={this.passwordToogle}
                                            className={styles.password_span_toggle}
                                        >
                                            <i className="fa fa-eye" aria-hidden="true" />
                                        </span>
                                    )
                                    : (
                                        <span
                                            onClick={this.passwordToogle}
                                            className={styles.password_span_toggle}
                                        >
                                            <i className="fa fa-eye-slash" aria-hidden="true" />
                                        </span>
                                    )}
                                <span className={styles.highlight} />
                                <span className={styles.bar} />
                                <span
                                    className={cx(styles.placeholderlabel, { [styles.labeltop]: this.state.password !== "" })}
                                    htmlFor={t("Password")}
                                >
                                    {t("password")}
                                </span>
                                {this.state.passwordError !== null
                                    && (
                                        <span className={styles.error_span}>
                                            {t(this.state.passwordError)}
                                        </span>
                                    )}
                                {this.props.userDetails.ERROR !== null
                                    && (
                                        <span className={styles.error_span}>
                                            {t(this.props.userDetails.ERROR)}
                                        </span>
                                    )}
                            </div>
                            <div
                                className={Style.forgote_link}
                            >
                                <a
                                    onClick={this.popupToggle}
                                >
                                    {t("Forgot Password?")}
                                </a>
                            </div>
                            <div className={Style.submit_btn}>
                                <input type="submit" value={t("Sign In")} />
                            </div>
                        </form>
                    </div>
                    <div
                        className={`${stylesPopup.popup_window} ${Style.forgot_password} ${stylesPopup.disNone}`}
                        ref={node => {
                            popUpDiv = node
                        }}
                    >
                        <div className={stylesPopup.popup_header}>
                            <div className={stylesPopup.container}>
                                <div
                                    className={stylesPopup.close_sign}
                                    onClick={() => {
                                        this.popupToggle()
                                        // document.body.classList.remove("noscroll")
                                        // document.documentElement.classList.remove("noscroll")
                                    }}
                                >
                                    <em className={`${StylesSprite.sprite_base} sprite_left_arrow_icon`} />
                                </div>
                                <h2>{t("Forgot Password")}</h2>
                            </div>
                        </div>
                        <div className={stylesPopup.popup_body}>
                            <div className={stylesPopup.container}>
                                <div className={Style.pleaseEnterYourEm}>
                                    {t("PLEASE_ENTER_YOUR_EMAIL_ADDRESS")}
                                </div>
                                <div className={Style.login_form_div}>
                                    <form onSubmit={this.forgotSubmitHandler}>
                                        <div className={cx(`${styles.group} ${styles.group_margin}`, { [styles.red_error]: this.props.forgoterror !== "" })}>
                                            <input
                                                type="email"
                                                name="email"
                                                value={this.state.email}
                                                onChange={
                                                    e => this.setState({ email: e.target.value })
                                                }
                                                required
                                            />
                                            <span className="highlight" />
                                            <span className="bar" />
                                            <span
                                                className={cx(styles.placeholderlabel, { [styles.labeltop]: this.state.email !== "" })}
                                                htmlFor={t("email")}
                                            >
                                                {t("email")}
                                            </span>
                                            {this.props.forgoterror !== ""
                                                && (
                                                    <span className={styles.error_span}>
                                                        {t(this.props.forgoterror)}
                                                    </span>
                                                )}
                                        </div>
                                        <div className={Style.submit_btn_forgot}>
                                            <input type="submit" value={t("Reset My Password")} />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className={`${stylesPopup.popup_window} ${Style.become_member_div} ${stylesPopup.disNone}`}
                        ref={node => {
                            becomeMemberDiv = node
                        }}
                    >
                        <div className={stylesPopup.popup_header}>
                            <div className={stylesPopup.container}>
                                <div
                                    className={stylesPopup.close_sign}
                                    onClick={() => {
                                        this.becomeMemberToggle()
                                        document.body.classList.remove("noscroll")
                                        document.documentElement.classList.remove("noscroll")
                                    }}
                                >
                                    <em className={`${StylesSprite.sprite_base} sprite_left_arrow_icon`} />
                                </div>
                                <h2>{t("Become Member")}</h2>
                            </div>
                        </div>
                        <div className={stylesPopup.popup_body}>
                            <div className={stylesPopup.container}>
                                <form onSubmit={this.becomeMemberSubmitHandler}>
                                    <div className={Style.signup_row_div}>
                                        <span>
                                            <input
                                                type="input"
                                                className={Style.signup_input}
                                                placeholder={t("first name")}
                                                name="first_name"
                                                value={this.state.fbfirst_name}
                                                onChange={e => {
                                                    this.setState({ fbfirst_name: e.target.value })
                                                }}
                                                required
                                            />
                                        </span>
                                    </div>

                                    <div className={Style.signup_row_div}>
                                        <span>
                                            <input
                                                type="input"
                                                name="last_name"
                                                className={Style.signup_input}
                                                placeholder={t("last name")}
                                                value={this.state.fblast_name}
                                                onChange={e => {
                                                    this.setState({ fblast_name: e.target.value })
                                                }}
                                                required
                                            />
                                        </span>
                                    </div>
                                    <div className={Style.signup_row_div}>
                                        <label htmlFor="Email">
                                            <span>
                                                <input
                                                    type="email"
                                                    name="email"
                                                    className={Style.signup_input}
                                                    required
                                                />
                                            </span>
                                            {t("Email")}
                                        </label>
                                    </div>

                                    <div className={Style.submit_btn_member}>
                                        <input type="submit" value={t("Become Member")} />
                                    </div>

                                </form>
                                <p>{this.props.forgoterror}</p>
                            </div>
                        </div>
                    </div>
                    <div className={Style.connect_div}>
                        <h3><span>{t("Or Connect With")}</span></h3>
                        <div className={Style.connect_link_div}>
                            <FacebookLogin
                                appId={config.facebook.appId}
                                isMobile
                                fields="first_name,last_name,name,email,picture,birthday,gender,id"
                                scope="email,public_profile,user_gender,user_birthday"
                                callback={this.responseFacebook}
                                onFailure={this.responseFacebookError}
                                cssClass={Style.facebooklink}
                                redirectUri={this.getFacebookRedirectURI()}
                            />
                            <GoogleLogin
                                clientId={config.google.auth.clientId}
                                buttonText={t("Google Login")}
                                onSuccess={this.responseGoogleSuccess}
                                onFailure={this.responseGoogleError}
                                className={Style.googlelink}
                            />
                        </div>
                        <div
                            className={Style.connect_note_div}
                            onClick={this.goForSignup}
                        >
                            <a>
                                {t("Don’t have an account? ")}
                                <span>
                                    {t("Sign Up")}
                                    {" "}
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
Login.propTypes = {
    authenticate: PropTypes.func.isRequired,
    authenticated: PropTypes.bool,
    history: PropTypes.oneOfType([PropTypes.object]).isRequired,
    userDetails: PropTypes.oneOfType([PropTypes.object]),
    // socialUserLogin: PropTypes.func.isRequired,
}
Login.defaultProps = {
    user: [],
    userDetails: {},
    authenticated: false,
    referer: "",
    isHomepage: false,
}
function mapStateToProps(state) {
    return {
        ...state.LoginReducer,
        ...state.WishlistReducer,
        ...state.MetaReducers,
    }
}
export default connect(
    mapStateToProps,
    {
        reInitSession,
        InitWishlist,
        socialUserLoginCheck,
        socialUserLogin,
        socialUserSignup,
        forgotPassword,
        localUserLogin,
        authenticate,
        getProfile,
        updateRequestedPath,
        clearUserDetails,
        getMeta,
    },
)(Login)
